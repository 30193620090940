var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: _vm.api } }),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                sm: "8",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "start", justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectBack },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _vm.isFirstLoading
                ? _c("v-skeleton-loader", {
                    ref: "skeleton",
                    attrs: { type: "table" },
                  })
                : _c(
                    "v-card",
                    { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "white--text text-h5 mb-4",
                          attrs: { color: "secondary" },
                        },
                        [_vm._v(" Assign New Person In Charge ")]
                      ),
                      _vm.isSelectingCompany
                        ? _c("div", [
                            _vm.isSelectingCompany
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        autocomplete: "off",
                                        items: _vm.companyOptions,
                                        dense: "",
                                        outlined: "",
                                        "item-text": "name",
                                        label: "Company",
                                        "return-object": "",
                                      },
                                      model: {
                                        value: _vm.form.company,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "company", $$v)
                                        },
                                        expression: "form.company",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-start" },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    "small-chips": "",
                                    "deletable-chips": "",
                                    multiple: "",
                                    "item-text": "staff_name",
                                    items: _vm.picOptions,
                                    dense: "",
                                    outlined: "",
                                    label: "Salesperson email ( CC to)",
                                    "return-object": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c("v-item", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.staff_name) +
                                                  " ( " +
                                                  _vm._s(item.role) +
                                                  " ) "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    583087365
                                  ),
                                  model: {
                                    value: _vm.form.pic,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "pic", $$v)
                                    },
                                    expression: "form.pic",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-end" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", plain: "" },
                                    on: { click: _vm.redirectBack },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      loading: _vm.api.isLoading,
                                      color: "primary",
                                    },
                                    on: { click: _vm.validateInput },
                                  },
                                  [_vm._v(" Confirm ")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }